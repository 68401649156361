/* Import Tailwind's base styles, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define custom colors */
@layer base {
  :root {
    --color-primary: #6c63ff; /* Primary color */
    --color-secondary: #ffd369; /* Secondary color */
    --color-accent: #ff6f61; /* Accent color */

    --color-bg-light: #fafafa; /* Light mode background */
    --color-bg-dark: #2d3748; /* Dark mode background */

    --color-text-light: #333333; /* Light text */
    --color-text-dark: #e2e8f0; /* Dark text */

    --color-border-light: #e0e0e0; /* Light mode border */
    --color-border-dark: #4a5568; /* Dark mode border */

    --color-shadow-light: rgba(0, 0, 0, 0.1); /* Light mode shadow */
    --color-shadow-dark: rgba(0, 0, 0, 0.5); /* Dark mode shadow */
  }
}

/* Global styles for body, headers, and default typography */
body {
  @apply bg-[var(--color-bg-light)] text-[var(--color-text-light)] antialiased; /* Light mode background and text */
  transition:
    background-color 0.5s ease,
    color 0.5s ease; /* Smooth transition for dark mode */
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  body {
    @apply bg-[var(--color-bg-dark)] text-[var(--color-text-dark)]; /* Dark mode background and text */
  }

  a {
    @apply text-blue-400 hover:text-blue-500; /* Link color in dark mode */
  }
}

/* Smooth scrolling for the entire app */
html {
  scroll-behavior: smooth;
}

/* Custom headers for improved readability */
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading text-[var(--color-text-light)] dark:text-[var(--color-text-dark)] font-bold tracking-tight transition-colors duration-300 ease-in-out;
}

p {
  @apply leading-relaxed; /* More space between lines for readability */
}

/* Anchor link hover styling for a more vibrant user experience */
a {
  @apply text-blue-600 hover:text-blue-800 transition-colors duration-300 ease-in-out;
  font-weight: 500;
}

/* Button, input, and textarea styles */
button,
input,
textarea {
  @apply focus:ring-2 focus:ring-blue-500 focus:outline-none; /* Focus states for accessibility */
  transition:
    background-color 0.3s ease,
    transform 0.2s ease; /* Smooth hover and focus states */
}

/* Custom button styling for primary, secondary, and accent buttons */
button.primary {
  @apply bg-gradient-to-r from-[var(--color-primary)] to-[var(--color-accent)] text-white py-2 px-4 rounded-lg shadow-md transition-transform duration-300 ease-in-out;
}

button.primary:hover {
  @apply from-[var(--color-accent)] to-[var(--color-primary)] transform scale-105 shadow-lg;
}

button.secondary {
  @apply bg-gradient-to-r from-[var(--color-secondary)] to-[var(--color-accent)] text-white py-2 px-4 rounded-lg shadow-md transition-transform duration-300 ease-in-out;
}

button.secondary:hover {
  @apply from-[var(--color-accent)] to-[var(--color-secondary)] transform scale-105 shadow-lg;
}

button.accent {
  @apply bg-gradient-to-r from-pink-500 to-orange-500 text-white py-2 px-4 rounded-lg shadow-md transition-transform duration-300 ease-in-out;
}

button.accent:hover {
  @apply from-pink-600 to-orange-600 transform scale-105 shadow-lg;
}

/* Custom form inputs for better aesthetics */
input,
textarea {
  @apply border border-[var(--color-border-light)] dark:border-[var(--color-border-dark)] rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-500 transition-shadow duration-200 ease-in-out;
}

input:hover,
textarea:hover {
  @apply border-gray-400; /* Light hover effect for inputs */
}

/* Custom animations for elements */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-up {
  animation: slideInUp 0.8s ease-in-out;
}

/* Custom loading spinner for forms or data loading */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
}

/* Custom modal styles */
.modal-backdrop {
  @apply fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center;
}

.modal-content {
  @apply bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-lg w-full p-6 relative transition-all duration-300 ease-in-out;
}

/* Custom modal close button */
.modal-close {
  @apply absolute top-3 right-3 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100;
}

/* Tooltip styling for hover-based guidance */
.tooltip {
  @apply absolute bg-gray-800 text-white text-xs rounded-lg px-2 py-1 shadow-lg z-10;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #4a5568 transparent transparent transparent;
}

/* Transition effects for modals */
.modal-open {
  opacity: 0;
  transform: translateY(-20px);
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.modal-open.show {
  opacity: 1;
  transform: translateY(0);
}

/* Global box shadows for cards, buttons, and forms */
.shadow-card {
  @apply shadow-md hover:shadow-lg transition-shadow duration-300;
}

/* Subtle card hover effects */
.card {
  @apply bg-[var(--color-bg-light)] border border-[var(--color-border-light)] dark:border-[var(--color-border-dark)] rounded-lg shadow-lg transition-transform duration-300 ease-in-out;
}

.card:hover {
  @apply transform scale-105 shadow-xl; /* More pronounced scaling on hover */
}

/* Enhanced focus styles for accessibility */
button:focus {
  @apply ring-2 ring-blue-400 outline-none; /* Focus ring */
}

input:focus,
textarea:focus {
  @apply border-blue-500; /* Border on focus */
}

/* Additional subtle elements for enhanced UX */
.card-content {
  @apply p-4 transition-transform duration-300 ease-in-out; /* Card content padding with transition */
}

.card-header {
  @apply text-lg font-semibold text-[var(--color-text-light)] dark:text-[var(--color-text-dark)] mb-2; /* Header styling */
}

.card-footer {
  @apply flex justify-between items-center mt-4; /* Footer layout */
}

.card-footer button {
  @apply text-sm bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200 ease-in-out; /* Subtle footer button styling */
}

/* Dark mode-specific adjustments for better visuals */
@media (prefers-color-scheme: dark) {
  button,
  input,
  textarea {
    @apply bg-gray-800 border-[var(--color-border-dark)] text-gray-200 transition-shadow duration-200 ease-in-out;
  }

  a {
    @apply text-blue-300 hover:text-blue-500;
  }

  .modal-content {
    @apply bg-gray-800;
  }

  .tooltip {
    @apply bg-gray-600;
  }

  /* Adjust card hover effects for dark mode */
  .card:hover {
    @apply transform scale-105 shadow-2xl;
  }
}
